<template>
  <div>
    <Echart
      id="centreLeft2Chart"
      ref="centreLeft2ChartRef"
      :options="options"
      height="4.5rem"
      width="4.125rem"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart';
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => [],
    },
    theme:{}
  },
  watch: {
    cdata: {
      handler(newData) {
        this.setData(newData)
      },
      immediate: true,
      deep: true,
    },
    theme(){
      this.setData(this.cdata)
    }
  },
  methods: {
    setData(newData){
      // 设置点的位置(经纬度)
        const geoCoordMap = {
          甘肃:[103.73, 36.03, 20],
          青海:[101.74, 36.56, 20],
          四川:[104.06, 30.67, 20],
          河北:[114.48, 38.03, 20],
          云南:[102.73, 25.04, 20],
          贵州:[106.71, 26.57, 20],
          湖北:[114.31, 30.52, 20],
          河南:[113.65, 34.76, 20],
          山东:[117, 36.65, 20],
          江苏:[118.78, 32.04, 20],
          安徽:[117.27, 31.86, 20],
          浙江:[120.19, 30.26, 20],
          江西:[115.89, 28.68, 20],
          福建:[119.3, 26.08, 20],
          广东:[113.23, 23.16, 20],
          湖南:[113, 28.21, 20],
          海南:[110.35, 20.02, 20],
          辽宁:[123.38, 41.8, 20],
          吉林:[125.35, 43.88, 20],
          黑龙江:[126.63, 45.75, 20],
          山西:[112.53, 37.87, 20],
          陕西:[108.95, 34.27, 20],
          台湾:[121.30, 25.03, 20],
          北京:[116.46, 39.92, 20],
          上海:[121.48, 31.22, 20],
          重庆:[106.54, 29.59, 20],
          天津:[117.2, 39.13, 20],
          内蒙古:[111.65, 40.82, 20],
          广西:[108.33, 22.84, 20],
          西藏:[91.11, 29.97, 20],
          宁夏:[106.27, 38.47, 20],
          新疆:[87.68, 43.77, 20],
          香港:[114.17, 22.28, 20],
          澳门:[113.54, 22.19, 20]
        }
        let seriesData = [
          { name: '甘肃', },
          { name: '青海', },
          { name: '四川', },
          { name: '河北', },
          { name: '云南', },
          { name: '贵州', },
          { name: '湖北', },
          { name: '河南', },
          { name: '山东', },
          { name: '江苏', },
          { name: '安徽', },
          { name: '浙江', },
          { name: '江西', },
          { name: '福建', },
          { name: '广东', },
          { name: '湖南', },
          { name: '海南', },
          { name: '辽宁', },
          { name: '吉林', },
          { name: '黑龙江', },
          { name: '山西', },
          { name: '陕西', },
          { name: '台湾', },
          { name: '北京', },
          { name: '上海', },
          { name: '重庆', },
          { name: '天津', },
          { name: '内蒙古', },
          { name: '广西', },
          { name: '西藏', },
          { name: '宁夏', },
          { name: '新疆', },
          { name: '香港', },
          { name: '澳门', },
        ];
        let convertData = function (data) {
          let scatterData = [];
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              scatterData.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value),
              });
            }
          }
          return scatterData;
        };
        if(this.theme=='light'){
          this.options = {
          showLegendSymbol: true,
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: point => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            // /*
              // formatter: (params) => {
              //   console.log(params)
              //   return `<div style=""> ${params} </div>`
              // }
            // */
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
            },
          },
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: '10%',
            left: '10%',
            map: 'china',
            roam: false,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(7,114,204, .8)',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
              },
              emphasis: {
                areaColor: '#00aeef',
              },
            },
          },
          series: [
            {
              name: '小车数量',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.2,
              mapType: 'china', // 自定义扩展图表类型
              top: '10%',
              left: '10%',
              itemStyle: {
                normal: {
                  color: 'red',
                  areaColor: 'rgba(19,54,162, .5)',
                  borderColor: 'rgba(0,242,252,.3)',
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: '#00f2fc',
                },
                emphasis: {
                  areaColor: '#c1232b',
                  borderColor: 'rgba(0,242,252,.6)',
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc',
                },
              },
              label: {
                formatter: params => `${params.name}`,
                show: false,//关闭标签
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#efefef',
                },
                emphasis: {
                  textStyle: {
                    color: '#fff',
                  },
                },
              },
			  // 模拟数据
			  data: newData,
			  
			  // 后台真实数据
              // data: newData,
			  
			  
              // select:{
              //   itemStyle:{
              //     areaColor:"#c1232b"
              //   }
              // }
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              symbolSize: 7,
              effectType: 'ripple',
              legendHoverLink: false,
              showEffectOn: 'render',
              rippleEffect: {
                period: 4,
                scale: 2.5,
                brushType: 'stroke',
              },
              zlevel: -1,
              itemStyle: {
                normal: {
                  color: '#99FBFE',
                  shadowBlur: 5,
                  shadowColor: '#fff',
                },
              },
              data: convertData(seriesData),
            },
          ],
        };
        }else{
          this.options = {
          showLegendSymbol: true,
          tooltip: {
            trigger: 'item',
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: point => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            // /*
              // formatter: (params) => {
              //   console.log(params)
              //   return `<div style=""> ${params} </div>`
              // }
            // */
          },
          visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
            },
          },
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: '10%',
            left: '10%',
            map: 'china',
            roam: false,
            itemStyle: {
              normal: {
                areaColor: 'rgba(0,0,0,0)',
                shadowColor: 'rgba(7,114,204, .8)',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
              },
              emphasis: {
                areaColor: '#00aeef',
              },
            },
          },
          series: [
            {
              name: '小车数量',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.2,
              mapType: 'china', // 自定义扩展图表类型
              top: '10%',
              left: '10%',
              itemStyle: {
                normal: {
                  color: 'red',
                  areaColor: 'rgba(19,54,162, .5)',
                  borderColor: 'rgba(0,242,252,.3)',
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: '#00f2fc',
                },
                emphasis: {
                  areaColor: '#4f7fff',
                  borderColor: 'rgba(0,242,252,.6)',
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc',
                },
              },
              label: {
                formatter: params => `${params.name}`,
                show: false,//关闭标签
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#efefef',
                },
                emphasis: {
                  textStyle: {
                    color: '#fff',
                  },
                },
              },
              data: newData,
              // select:{
              //   itemStyle:{
              //     areaColor:"#c1232b"
              //   }
              // }
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              symbolSize: 7,
              effectType: 'ripple',
              legendHoverLink: false,
              showEffectOn: 'render',
              rippleEffect: {
                period: 4,
                scale: 2.5,
                brushType: 'stroke',
              },
              zlevel: -1,
              itemStyle: {
                normal: {
                  color: '#99FBFE',
                  shadowBlur: 5,
                  shadowColor: '#fff',
                },
              },
              data: convertData(seriesData),
            },
          ],
        };
        }
        // 重新选择区域
        this.handleMapRandomSelect();
    },
    // 开启定时器
    startInterval() {
      const _self = this;
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000;
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea();
      }, time);
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = 9;
      this.$nextTick(() => {
        const map = this.$refs.centreLeft2ChartRef.chart;
        let index = Math.floor(Math.random() * length);
        while (index === this.preSelectMapIndex || index >= length) {
          index = Math.floor(Math.random() * length);
        }
        map.dispatchAction({
          type: 'mapUnSelect',
          seriesIndex: 0,
          dataIndex: this.preSelectMapIndex,
        });
        map.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: index,
        });
        map.dispatchAction({
          type: 'mapSelect',
          seriesIndex: 0,
          dataIndex: index,
        });
        this.preSelectMapIndex = index;
      });
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        const map = this.$refs.centreLeft2ChartRef.chart;
        const _self = this;
        setTimeout(() => {
          _self.reSelectMapRandomArea();
        }, 0);
        // 移入区域，清除定时器、取消之前选中并选中当前
        map.on('mouseover', function (params) {
          clearInterval(_self.intervalId);
          map.dispatchAction({
            type: 'mapUnSelect',
            seriesIndex: 0,
            dataIndex: _self.preSelectMapIndex,
          });
          map.dispatchAction({
            type: 'mapSelect',
            seriesIndex: 0,
            dataIndex: params.dataIndex,
          });
          _self.preSelectMapIndex = params.dataIndex;
        });
        // 移出区域重新随机选中地图区域，并开启定时器
        map.on('globalout', function () {
          _self.reSelectMapRandomArea();
          _self.startInterval();
        });
        this.startInterval();
      });
    },
  },
};
</script>
