<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-bar"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">计划任务</span>
          <dv-decoration-3 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <CentreLeft1Chart :cdata="cdata" :theme="theme" />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div class="item-box" v-for="(item,index) in numberData" :key="index">
          <div class="d-flex">
            <!-- <span class="coin">￥</span> -->
            <dv-digital-flop :config="item.number" style="width:2.5rem;height:.625rem;" />
          </div>
          <p class="text" style="text-align: center;">
            {{item.text}}
            <span class="colorYellow">(件)</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CentreLeft1Chart from "@/components/echart/centerLeft/centerLeft1Chart";
import {taskList} from '@/api/car'

export default {
  props:{
    carId:{
      type:String,
    },
    typeId:{
      // type:Number,
    },
    theme:{}
  },
  watch:{
    carId(newVal,oldVal){
      this.setData()
    }
  },
  data() {
    return {
      numberData: [
        {
          number: {
            number: [15],
            toFixed: 0,
            content: "{nt}"
          },
          text: "未开始"
        },
        {
          number: {
            number: [1144],
            toFixed: 0,
            content: "{nt}"
          },
          text: "进行中"
        },
        {
          number: {
            number: [361],
            toFixed: 0,
            content: "{nt}"
          },
          text: "已完成"
        },
        {
          number: {
            number: [157],
            toFixed: 0,
            content: "{nt}"
          },
          text: "已删除"
        }
      ],
      cdata:{
        xData: ["洒水", "扫地", "震尘"],
        seriesData: [
          { value: 60, name: "洒水" },
          { value: 50, name: "扫地" },
          { value: 70, name: "震尘" },
        ]
      }
    };
  },
  components: {
    CentreLeft1Chart
  },
  mounted() {
    // this.changeTiming();
    this.setData()
  },
  methods: {
    changeTiming() {
      setInterval(() => {
        this.changeNumber();
      }, 3000);
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index;
        item.number = { ...item.number };
      });
    },
    setData(){
      if (this.typeId!=2&&this.typeId!=3) {
          return
        }
      taskList(this.carId).then(res=>{
        let tasks=res.data
        let work1=tasks.filter(item=>item.work==1)
        let work2=tasks.filter(item=>item.work==2)
        let work3=tasks.filter(item=>item.work==3)
        if(this.typeId==2){
          this.cdata={
            xData: ["洒水", "震尘", "扫地"],
            seriesData: [
              { value: work1.length, name: "洒水" },
              { value: work2.length, name: "扫地" },
              { value: work3.length, name: "震尘" },
            ]
          }
        }else{
          this.cdata={
            xData: ["吸水", "洗地"],
            seriesData: [
              { value: work1.length, name: "吸水" },
              { value: work3.length, name: "洗地" },
            ]
          }
        }
        let status0=tasks.filter(item=>item.status==0)
        let status1=tasks.filter(item=>item.status==1)
        let status2=tasks.filter(item=>item.status==2)
        let status3=tasks.filter(item=>item.status==3)
        this.numberData = [
          {
            number: {
              number: [status1.length],
              toFixed: 0,
              content: "{nt}"
            },
            text: "未开始"
          },
          {
            number: {
              number: [status2.length],
              toFixed: 0,
              content: "{nt}"
            },
            text: "进行中"
          },
          {
            number: {
              number: [status3.length],
              toFixed: 0,
              content: "{nt}"
            },
            text: "已完成"
          },
          {
            number: {
              number: [status0.length],
              toFixed: 0,
              content: "{nt}"
            },
            text: "已删除"
          }
        ];
      })
    }
  }
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
    }
  }
}
</style>