<template>
	<div>
		<Echart :options="options" id="centreLeft1Chart" height="2.75rem" width="3.25rem"></Echart>
	</div>
</template>

<script>
	import Echart from '@/common/echart'
	export default {
		data() {
			return {
				options: {},
			};
		},
		components: {
			Echart,
		},
		props: {
			cdata: {
				type: Object,
				default: () => ({})
			},
			theme: {}
		},
		watch: {
			cdata: {
				handler(newData) {
					this.setData(newData)
				},
				immediate: true,
				deep: true
			},
			theme() {
				this.setData(this.cdata)
			}
		},
		methods: {
			setData(newData) {
				if (this.theme == 'light') {
					this.options = {
						color: [
							"#e01f54",
							"#9bca63",
							"#fcce10"
							// "#37a2da",
							// "#32c5e9",
							// "#9fe6b8",
							// "#ffdb5c",
							// "#ff9f7f",
							// "#fb7293",
							// "#e7bcf3",
							// "#8378ea"
						],
						tooltip: {
							trigger: "item",
							formatter: "{a} <br/>{b} : {c} ({d}%)"
						},
						toolbox: {
							show: true
						},
						calculable: true,
						legend: {
							orient: "horizontal",
							icon: "circle",
							bottom: 0,
							x: "center",
							data: newData.xData,
							textStyle: {
								color: "#fff",
								// fontSize:"0.25rem"
							}
						},
						series: [{
							name: "任务统计",
							type: "pie",
							labelLine: {
								show: false
							},
							label: {
								show: false,

							},
							radius: 65,
							center: ['50%', '50%'],
							// radius: [10, 70],
							roseType: "area",
							// center: ["50%", "40%"],
							data: newData.seriesData
						}]
					}
				} else {
					this.options = {
						color: [
							// "#e01f54",
							// "#9bca63",
							// "#fcce10"
							"#37a2da",
							"#32c5e9",
							"#9fe6b8",
							// "#ffdb5c",
							// "#ff9f7f",
							// "#fb7293",
							// "#e7bcf3",
							// "#8378ea"
						],
						tooltip: {
							trigger: "item",
							formatter: "{a} <br/>{b} : {c} ({d}%)"
						},
						toolbox: {
							show: true
						},
						calculable: true,
						legend: {
							orient: "horizontal",
							icon: "circle",
							bottom: 0,
							x: "center",
							data: newData.xData,
							textStyle: {
								color: "#fff",
								// fontSize:"0.25rem"
							}
						},
						series: [{
							name: "任务统计",
							type: "pie",
							radius: 65,
							center: ['50%', '50%'],
							radius: 48,
							labelLine: {
								show: false
							},
							label: {
								show: false,

							},
							roseType: "area",
							data: newData.seriesData
						}]
					}
				}

			}
		}
	};
</script>

<style lang="scss" scoped>
	.test {
		color: #37a2da;
		color: #32c5e9;
		color: #9fe6b8;
		color: #ffdb5c;
		color: #ff9f7f;
		color: #fb7293;
		color: #e7bcf3;
		color: #8378ea;
	}
</style>
