<template>
  <div :id="theme=='light'?'index':'index-dark'">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 style="width:33.3%;height:.0625rem;" />
          <div class="d-flex jc-center">
            <dv-decoration-8 :color="['#568aea', '#568aea']" style="width:2.5rem;height:.625rem;" />
            <div class="title">
              <span class="title-text">太机脑智能驾驶监控平台</span>
              <dv-decoration-6
                class="title-bototm"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
                style="width:4.125rem;height:.1rem;"
              />
            </div>
            <dv-decoration-8
              :reverse="true"
              :color="['#568aea', '#568aea']"
              style="width:2.5rem;height:.625rem;"
            />
          </div>
          <!-- <div
            class="react-left mr-4"
            style="width: 6.25rem; background-color: #0f1325; text-align: right;"
          >
            <span class="react-after"></span>
            <span class="text">{{dateYear}} {{dateWeek}} {{dateDay}}</span>
          </div> -->
          <dv-decoration-10 style="width:33.3%;height:.0625rem; transform: rotateY(180deg);" />
        </div>

        <!-- 第二行 -->
        <!-- <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 40%">
            <div
              class="react-right ml-4"
              style="width: 6.25rem; text-align: left;background-color: #0f1325;"
            >
              <span class="react-before"></span>
              <span class="text">数据分析1</span>
            </div>
            <div class="react-right ml-3" style="background-color: #0f1325;">
              <span class="text colorBlue">数据分析2</span>
            </div>
          </div>
          <div style="width: 40%" class="d-flex">
            <div class="react-left bg-color-blue mr-3">
              <span class="text fw-b">adt-big-screen</span>
            </div>
            <div
              class="react-left mr-4"
              style="width: 6.25rem; background-color: #0f1325; text-align: right;"
            >
              <span class="react-after"></span>
              <span class="text">{{dateYear}} {{dateWeek}} {{dateDay}}</span>
            </div>
          </div>
        </div> -->

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div>
              <dv-border-box-12>
                <centerLeft1 style="width: 20px;" :carId="carId" :typeId="typeId" :theme="theme" />
              </dv-border-box-12>
            </div>
            <div>
              <dv-border-box-12>
                <centerLeft2 :theme="theme" />
              </dv-border-box-12>
            </div>
            <!-- 中间 -->
            <div>
              <center :record="record" :carId="carId" :theme="theme" @changeTheme="changeTheme"/>
            </div>
            <!-- 中间 -->
            <div>
              <centerRight2 :record="record" :theme="theme" />
            </div>
            <div>
              <dv-border-box-13>
                <centerRight1 @setCarId="setCarId($event)" @setTypeId="setTypeId($event)" :theme="theme" />
              </dv-border-box-13>
            </div>
          </div>

          <!-- 第四行数据 -->
          <div class="bototm-box">
            <dv-border-box-13>
              <bottomLeft :carId="carId" :theme="theme" />
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomRight :res="bottomRes" :theme="theme" />
            </dv-border-box-12>
          </div>
          <!--  -->
          <div class="last">
            <dv-border-box-8>
              <bottom :res="bottomRes" :theme="theme" />
            </dv-border-box-8>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { formatTime } from '../utils/index.js'
import centerLeft1 from "./centerLeft1";
import centerLeft2 from "./centerLeft2";
import centerRight1 from "./centerRight1";
import centerRight2 from "./centerRight2";
import center from "./center";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import bottom from './bottom'

import {getEchartDataMonitor,getRecord} from '@/api/car'

export default {
  data () {
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      carId:'549207404844158976',
      typeId:'1',
      bottomRes:null,
      record:null,
      theme:localStorage.getItem("theme")?localStorage.getItem("theme"):"light",
    };
  },
  watch:{
    carId(newVal){
      this.setChartData();
      this.setCenterData()
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    centerRight1,
    centerRight2,
    center,
    bottomLeft,
    bottomRight,
    bottom,
  },
  mounted () {
    this.timeFn();
    this.cancelLoading();
    this.setChartData();
    this.setCenterData()
    setInterval(()=>{
      this.setCenterData()
    },1000)
    if(!this.theme){
      this.theme="light"
    }
  },
  methods: {
    setCarId(carId){
      // console.log(carId)
      this.carId=carId
    },
    setTypeId(typeId){
      console.log(typeId)
      this.typeId=typeId
    },
    timeFn () {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss');
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000)
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    setChartData(){
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");
      var defaultDate = `${year}-${month}-${date}`;
			getEchartDataMonitor(this.carId,defaultDate).then(res=>{
        this.bottomRes=res
      })
    },
    setCenterData(){
      getRecord(this.carId).then(res=>{
          this.record=res.data[0]
      })
    },
    changeTheme(){
      this.theme=this.theme=="light"?"dark":"light";
      localStorage.setItem("theme",this.theme)
    }
  }
};
</script>

<style lang="scss">
@import '../assets/scss/index.scss';
@import '../assets/scss/index-dark.scss';
</style>