<template>
  <div>
    <Chart :cdata="cdata" :theme="theme" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  props:{
    cdata:{
      type:Object,
      default:{
        xData: ["洒水", "扫地", "震尘"],
        seriesData: [
          { value: 10, name: "洒水" },
          { value: 5, name: "扫地" },
          { value: 15, name: "震尘" },
        ]
      }
    },
    theme:{}
  },
  data () {
    return {
      // cdata: 
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
}
</script>

<style lang="scss" scoped>
</style>