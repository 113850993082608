<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {getOnlineByIdTime} from '@/api/car'

export default {
  props:{
    carId:{
      type:String,
      default:'549207404844158976',
    },
    theme:{}
  },
  watch:{
    carId(){
      this.setOnlineTimeChart();
    },
    theme(){
      this.setOnlineTimeChart()
    }
  },
  data () {
    return {
      cdata: {
        title:{
          text:'在线时间(分钟)'
        },
        tooltip:{},
        legend:{
            data:['时长']
        },
        grid:{
          x:"40",
          width:"90%",
          height:"60%"// 高度
        },
        xAxis:{
            data:[]
        },
        yAxis:{},
        series:[{
            name:'时长',
            type:'bar',
            data:[],
        }]
      },
      timevalue:'',
		  dateList:[],
		  beforeDate:'',
		  startDate:'',
		  dataMonitorReq:{
			  carId:'',
			  dateList:'',
		  },
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.getNowTime();
    this.getNowDate();
    this.setData();
  },
  methods: {
    getDateList(){
		  //组装日期数组用于每日在线时长统计
		  this.dateList=[];
		  for(let i=30;i>=0;i--){
			  this.dateList.push(this.getBeforeDate(i));
		  }
    },
    /**
	   * 获取当前日期的前n天的日期值
	   */
	  getBeforeDate(n){
		  let dateStr = this.timevalue;
		  dateStr = dateStr.replace(/-/g, '/');
		  let date = new Date(dateStr);
		  date.setDate(date.getDate() - n);
		  return this.dateFormat("YYYY-mm-dd", date);
	  },
	  /**
	   * 日期格式化
	   * @param {Object} fmt 
	   * @param {Object} date
	   */
	  dateFormat(fmt, date){
		  let ret;
		  date=new Date(date);
		  	    const opt = {
		  	        "Y+": date.getFullYear().toString(),        // 年
		  	        "m+": (date.getMonth() + 1).toString(),     // 月
		  	        "d+": date.getDate().toString(),            // 日
		  	        "H+": date.getHours().toString(),           // 时
		  	        "M+": date.getMinutes().toString(),         // 分
		  	        "S+": date.getSeconds().toString()          // 秒
		  	        // 有其他格式化字符需求可以继续添加，必须转化成字符串
		  	    };
		  	    for (let k in opt) {
		  	        ret = new RegExp("(" + k + ")").exec(fmt);
		  	        if (ret) {
		  	            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
		  	        };
		  	    };
		  	    return fmt;
    },
    setOnlineTimeChart(){
		  this.dataMonitorReq.carId=this.carId;
		  this.dataMonitorReq.dateList=this.dateList;
		  getOnlineByIdTime(this.dataMonitorReq).then(res=>{
			  // 在线时长统计
			  // console.log(this.dateList);
			  if(res.data==null){
				  this.cdata={
			  title:{
				  text:'每日在线时间统计',
				  textStyle: {
						fontWeight: "lighter",
						fontFamily: "Courier New"
				   },
			  },
			  tooltip:{},
			  legend:{
				  data:['时长'],
			  },
			  xAxis:{
				  data:[]
			  },
			  yAxis:{},
			  series:[{
				  name:'时长',
				  type:'bar',
				  data:[]
			  }]
		  };
			  }else{
          if(this.theme=='light'){
            this.cdata={
					  title:{
						  text:'每日在线时间统计(分钟)',
						  textStyle: {
								fontWeight: "lighter",
								fontFamily: "Courier New"
						   },
					  },
					  tooltip:{},
					  legend:{
					  		data:['时长']
					  },
					  grid:{
              y:"35",
						  x:"40",
						  width:"90%",
						  height:"60%"// 高度
					  },
					  xAxis:{
					  		data:this.dateList
					  },
					  yAxis:{},
					  series:[{
					  	  name:'时长',
					  	  type:'bar',
                data:res.data,
                itemStyle:{
                  normal:{
                    color:'#e87c25',
                  }
                }
            }],
				  };
          }else{
            this.cdata={
					  title:{
						  text:'每日在线时间统计(分钟)',
						  textStyle: {
								fontWeight: "lighter",
								fontFamily: "Courier New"
						      },
						  
					  },
					  
					  tooltip:{},
					  legend:{
					  		data:['时长']
					  },
					  grid:{
              y:"35",
						  x:"40",
						  width:"90%",
						  height:"60%"// 高度
					  },
					  xAxis:{
					  		data:this.dateList
					  },
					  yAxis:{},
					  series:[{
					  	  name:'时长',
					  	  type:'bar',
                data:res.data,
            }],
				  };
          }
				  
			  }
		  })
    },
    /**
	   * 获取当前日期
	   */
	  getNowDate(){
		  let date=new Date();
		  let seperator1="-";
		  let month = date.getMonth() + 1<10? "0"+(date.getMonth() + 1):date.getMonth() + 1;
		  let strDate = date.getDate()<10? "0" + date.getDate():date.getDate();
		  let currentdate = date.getFullYear() + seperator1  + month  + seperator1  + strDate;
		  this.beforeDate=currentdate;
		  this.startDate=currentdate;
	  },
	  getNowTime() {
        var now = new Date();
        var year = now.getFullYear(); //得到年份
        var month = now.getMonth(); //得到月份
        var date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");
        var defaultDate = `${year}-${month}-${date}`;
    this.timevalue=defaultDate;
        // this.$set(this.searchFormField, "date", defaultDate);
    },
    // 根据自己的业务情况修改
    setData () {
      this.getDateList();
      this.setOnlineTimeChart();
    },
  }
};
</script>

<style lang="scss" scoped>

</style>