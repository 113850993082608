<template>
  <div id="centreRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-line"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">robokit</span>
        </div>
        <input class="name-input" v-model="name" @keyup.13="setCarList" />
      </div>
      <div class="d-flex jc-center body-box">
        <!-- <input class="name-input" v-model="name" @keyup.13="setCarList" /> -->
        <dv-scroll-board :config="config" style="width:3.375rem;height:4.28rem" @click="chooseRow"/>
        <!-- <dv-scroll-board :config="config" style="width:3.375rem;height:4.0rem" @click="chooseRow"/> -->
      </div>
    </div>
  </div>
</template>

<script>
import {carList} from '@/api/car'
export default {
  props:{
    theme:{}
  },
  watch:{
    theme(){
      this.setCarList()
    }
  },
  data() {
    return {
      config: {
        header: ["ID", "名称"],
        data: [
          [432515341168414720,'扬帆号']
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        columnWidth: [70],
        align: ["center"]
      },
      cars:[],
      name:'',
    };
  },
  components: {},
  mounted() {
    this.setCarList()
  },
  methods: {
    chooseRow(item){//row,ceil,rowIndex,columnIndex
      console.log(item)
      // this.$emit('setTypeId',this.getCarType(row.row[1]))
      // this.$emit('setCarId',row.row[1])
      this.$emit('setTypeId',this.cars[item.rowIndex].typeId)
      this.$emit('setCarId',this.cars[item.rowIndex].id)
    },
    getCarType(carId){
      for(let car of this.cars){
        if(car.id==carId){
          return car.typeId;
        }
      }
    },
    setCarList(){
      carList({entityId:1,pageIndex:1,pageSize:100,fuzzySearch:true},{name:this.name}).then(res=>{
        // console.log(res.pageData.datas)
        this.cars=res.pageData.datas
        let data=res.pageData.datas.map(item=>{
          // return [item.id,item.name]
          return [item.name]
        })
        if(this.theme=='light'){
          this.config={
            // header: ["ID", "名称"],
            header: ["名称"],
            data: data,
            rowNum: 7, //表格行数
            headerHeight: 35,
            headerBGC: "#0f132530", //表头
            // oddRowBGC: "#0f1325", //奇数行
            // evenRowBGC: "#171c33", //偶数行
            oddRowBGC: "#0f132530", //奇数行
            evenRowBGC: "#171c3330", //偶数行
            index: true,
            columnWidth: [50],
            align: ["center"]
          }
        }else{
          this.config={
            // header: ["ID", "名称"],
            header: ["名称"],
            data: data,
            rowNum: 7, //表格行数
            headerHeight: 35,
            headerBGC: "#0f1325", //表头
            oddRowBGC: "#0f1325", //奇数行
            evenRowBGC: "#171c33", //偶数行
            // oddRowBGC: "#0f132530", //奇数行
            // evenRowBGC: "#171c3330", //偶数行
            index: true,
            columnWidth: [50],
            align: ["center"]
          }
        }
        
      })
    }
  }
};
</script>

<style lang="scss">
.name-input{
  color: #c3cbde;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid;
  border-color: #172e66;
  border-radius: 0.1rem;
  text-align: center;
}
#centreRight1 {
  padding: 0.2rem;
  height: 5.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>