<template>
	<div class="bottom">
		<Chart :cdata="cdata1" />
		<Chart :cdata="cdata2" />
		<Chart :cdata="cdata3" />
	</div>
</template>

<script>
	import Chart from './chart.vue'
	export default {
		data() {
			return {
				cdata1: {},
				cdata2: {},
				cdata3: {},
				axisLine: {
					lineStyle: {
						color: "#44cef6",
						width: 2,
					}
				},
			};
		},
		props: {
			res: {
				type: Object,
				default: null,
			},
			theme: {}
		},
		watch: {
			res(newVal, oldVal) {
				//   console.log(newVal,oldVal)
				this.setData(newVal)
			},
			theme() {
				this.setData(this.res)
			}
		},
		components: {
			Chart,
		},
		mounted() {
			this.setData(this.res);
		},
		methods: {
			/**
			 * 日期格式化
			 * @param {Object} fmt 
			 * @param {Object} date
			 */
			dateFormat(fmt, date) {
				let ret;
				date = new Date(date);
				const opt = {
					"Y+": date.getFullYear().toString(), // 年
					"m+": (date.getMonth() + 1).toString(), // 月
					"d+": date.getDate().toString(), // 日
					"H+": date.getHours().toString(), // 时
					"M+": date.getMinutes().toString(), // 分
					"S+": date.getSeconds().toString() // 秒
					// 有其他格式化字符需求可以继续添加，必须转化成字符串
				};
				for (let k in opt) {
					ret = new RegExp("(" + k + ")").exec(fmt);
					if (ret) {
						fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
					};
				};
				return fmt;
			},
			// 根据自己的业务情况修改
			setData(res) {
				let resList = res.lst;
				let dataList = new Array; // 时间
				let valueList1 = new Array; //电流	
				let valueList2 = new Array; //电压
				let valueList3 = new Array; //电量
				for (let i = 0; i < resList.length; i++) {
					dataList.push(this.dateFormat("YYYY-mm-dd HH:MM:SS", resList[i].createTime));
					valueList1.push(resList[i].powerLeft);
					valueList2.push(resList[i].voltage);
					valueList3.push(resList[i].electric);
				}
				if (this.theme == 'light') {
					this.cdata1 = {
						title: {
							text: '电量(%)',
							textStyle: {
								fontWeight: "lighter",
								fontFamily: "Courier New"
							},
						},
						legend: {
							data: ['电量']
						},
						xAxis: {
							data: dataList,
							axisLine: this.axisLine,
						},
						yAxis: {
							axisLine: this.axisLine,

						},
						series: [{
							name: '电量',
							type: 'line',
							data: valueList1,
							markLine: {
								silent: true,
								/* data: [{
									yAxis: 20
								}, {
									yAxis: 40
								}, {
									yAxis: 60
								}, {
									yAxis: 80
								}, {
									yAxis: 100
								}] */
							},
							itemStyle: {
								color: "#cbb0e3"
							},
						}]
					}
					this.cdata2 = {
						title: {
							text: '电压(V)',
							textStyle: {
								fontWeight: "lighter",
								fontFamily: "Courier New"
							},
						},
						legend: {
							data: ['电压']
						},
						xAxis: {
							data: dataList,
							axisLine: this.axisLine,
							
						},
						yAxis: {
							axisLine: this.axisLine,
						},
						series: [{
							name: '电压',
							type: 'line',
							data: valueList2,
							markLine: {
								silent: true,
								/* data: [{
									yAxis: 5
								}, {
									yAxis: 10
								}, {
									yAxis: 15
								}, {
									yAxis: 20
								}] */
							},
							itemStyle: {
								color: "#a5e7f0"
							}
						}]
					}
					this.cdata3 = {
						title: {
							text: '电流(A)',
							textStyle: {
								fontWeight: "lighter",
								fontFamily: "Courier New"
							},
						},
						legend: {
							data: ['电流']
						},
						xAxis: {
							data: dataList,
							axisLine: this.axisLine,
						},
						yAxis: {
							axisLine: this.axisLine,
						},
						series: [{
							name: '电流',
							type: 'line',
							data: valueList3,
							markLine: {
								silent: true,
								/* data: [{
									yAxis: 0.3
								}, {
									yAxis: 0.6
								}, {
									yAxis: 0.9
								}, {
									yAxis: 1.2
								}] */
							},
							itemStyle: {
								color: "#e87c25"
							}
						}]
					}
				} else {
					this.cdata1 = {
						title: {
							text: '电量(%)',
							textStyle: {
								fontWeight: "lighter",
								fontFamily: "Courier New"
							},
						},
						legend: {
							data: ['电量']
						},
						xAxis: {
							data: dataList,
							axisLine: this.axisLine,
						},
						yAxis: {
							axisLine: this.axisLine,
						},
						series: [{
							name: '电量',
							type: 'line',
							data: valueList1,
							markLine: {
								silent: true,
								/* data: [{
									yAxis: 20
								}, {
									yAxis: 40
								}, {
									yAxis: 60
								}, {
									yAxis: 80
								}, {
									yAxis: 100
								}] */
							}
						}]
					}
					this.cdata2 = {
						title: {
							text: '电压(V)',
							textStyle: {
								fontWeight: "lighter",
								fontFamily: "Courier New"
							},
						},
						legend: {
							data: ['电压']
						},
						xAxis: {
							data: dataList,
							axisLine: this.axisLine,
						},
						yAxis: {
							axisLine: this.axisLine,
						},
						series: [{
							name: '电压',
							type: 'line',
							data: valueList2,
							markLine: {
								silent: true,
								/* data: [{
									yAxis: 5
								}, {
									yAxis: 10
								}, {
									yAxis: 15
								}, {
									yAxis: 20
								}] */
							}
						}]
					}
					this.cdata3 = {
						title: {
							text: '电流(A)',
							textStyle: {
								fontWeight: "lighter",
								fontFamily: "Courier New"
							},
						},
						legend: {
							data: ['电流']
						},
						xAxis: {
							data: dataList,
							axisLine: this.axisLine,
						},
						yAxis: {
							axisLine: this.axisLine,
						},
						series: [{
							name: '电流',
							type: 'line',
							data: valueList3,
							markLine: {
								silent: true,
								/* data: [{
									yAxis: 0.3
								}, {
									yAxis: 0.6
								}, {
									yAxis: 0.9
								}, {
									yAxis: 1.2
								}] */
							}
						}]
					}
				}
			},
		}
	};
</script>

<style lang="scss" scoped>
	.bottom {
		display: grid;
		grid-template-columns: repeat(3, 33.33%);
	}
</style>
