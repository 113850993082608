<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
import {randomNum} from '../../../../utils/random'

export default {
  components: {
    Chart,
  },
  props:{
    record:{
      type:Object,
    }
  },
  watch:{
    record:{
      handler:function(newVal){
        // console.log('centerRight2-record:',newVal)
        if (!newVal) {
          return
        }
        if(newVal.ultrasonic_1==0&&newVal.ultrasonic_2==0&&newVal.ultrasonic_3==0&&newVal.ultrasonic_4==0&&
        newVal.ultrasonic_5==0&&newVal.ultrasonic_6==0&&newVal.ultrasonic_7==0&&newVal.ultrasonic_8==0){
          newVal={...newVal,...this.randomUltrasonic()}
        }

        setTimeout(()=>{
          this.cdata={
          indicatorData: [
            { name: '雷达1', max: 505},
            { name: '雷达2', max: 505},
            { name: '雷达3', max: 505},
            { name: '雷达4', max: 505},
            { name: '雷达5', max: 505},
            { name: '雷达6', max: 505},
            { name: '雷达7', max: 505},
            { name: '雷达8', max: 505},
          ],
          dataBJ:[
            this.filterUltrasonic([newVal.ultrasonic_1,newVal.ultrasonic_2,newVal.ultrasonic_3,newVal.ultrasonic_4,
                                                newVal.ultrasonic_5,newVal.ultrasonic_6,newVal.ultrasonic_7,newVal.ultrasonic_8, ])
          ]
        }
        },100)
      },
      deep: true,
	    immediate: true,
    },
  },
  data () {
    return {
      cdata: {
        indicatorData: [
          { name: 'UW1', max: 505},
          { name: 'UW2', max: 505},
          { name: 'UW3', max: 505},
          { name: 'UW4', max: 505},
          { name: 'UW5', max: 505},
          { name: 'UW6', max: 505},
          { name: 'UW7', max: 505},
          { name: 'UW8', max: 505},
        ],
        dataBJ: [
          [200, 400, 200, 400, 300, 400,500,400]
        ],
      }
    }
  },
  methods: {
    randomUltrasonic(){
      return{
        ultrasonic_1:randomNum(50,505),
        ultrasonic_2:randomNum(50,505),
        ultrasonic_3:randomNum(50,505),
        ultrasonic_4:randomNum(50,505),
        ultrasonic_5:randomNum(50,505),
        ultrasonic_6:randomNum(50,505),
        ultrasonic_7:randomNum(50,505),
        ultrasonic_8:randomNum(50,505),
      }
    },
    filterUltrasonic(vals){
        return vals.map((item,index)=>{
            if (item>505) {
                return 505
            }else{
                return item
            }
        })
    },
  },
  mounted () {
  },
}
</script>

<style lang="scss" scoped>
</style>