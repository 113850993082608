import axios from 'axios'
import request from './index'

export const carList = (listQuery, newParams) => {
  return request({
    url: '/car/list',
    method: 'post',
    data: {
      command_id: 25,
      entityId: listQuery.entityId,
      pageIndex: listQuery.pageIndex,
      pageSize: listQuery.pageSize,
      params: newParams != null ? newParams : listQuery.params,
      fuzzySearch: listQuery.fuzzySearch
    }
  })
}

export const carListByParam = (listQuery, newParams) => {
  return request({
    url: '/car/doPage',
    method: 'post',
    data: {
      command_id: 25,
      isRobot: listQuery.isRobot,
	  carIdStr:listQuery.carIdStr,
      pageIndex: listQuery.pageIndex,
      pageSize: listQuery.pageSize,
      params: newParams != null ? newParams : listQuery.params,
      fuzzySearch: listQuery.fuzzySearch
    }
  })
}

export const carTypeList = listQuery => {
  return request({
    url: '/car/type/list',
    method: 'post',
    data: {
      command_id: 44,
	  entityId: listQuery.entityId,
      pageIndex: listQuery.pageIndex,
      pageSize: listQuery.pageSize,
      params: listQuery.params,
      fuzzySearch: listQuery.fuzzySearch
    }
  })
}


export const carEditFunction = formCarData => {
	return request({
		url:'/car/carEdit',
		method:'post',
		data:{
			carEditId: formCarData.carEditId,
			carEditName: formCarData.carEditName,
			carEditIsRobot: formCarData.carEditIsRobot,
      carEditEntityId: formCarData.carEditEntityId,
      carEditTypeId: formCarData.carEditTypeId,
		}
	})
}

export const carDelete = carId => {
	return request({
		url:'/car/carDelete/'+carId,
		method:'delete',
	})
}

export const getTotaltime = carId => {
	return request({
		url:'/car/dataMonitor/getTotalTime/'+carId,
		method:'get',
	})
}

export const getOnlineTime = carId => {
	return request({
		url:'/car/dataMonitor/getOnlineTime/'+carId,
		method:'get',
	})
}

export const getTotalTime = () => {
	return request({
		url:'/car/dataMonitor/getTotalTime',
		method:'get',
	})
}

export const getEchartDataMonitor = (carId,currentTime) => {
	return request({
		url:'/car/dataMonitor/getEchartDataMonitor/'+carId+'/'+currentTime,
		method:'get',
	})
}

export const getOnlineByIdTime = dataMonitorReq => {
	return request({
		url:'/car/dataMonitor/getOnlineByIdTime',
		method:'post',
		data:{
			carId: dataMonitorReq.carId,
			dateList: JSON.stringify(dataMonitorReq.dateList),
		}
	})
}

export const getCarById = carId => {
	return request({
		url:'/car/dataMonitor/getCarById/'+carId,
		method:'get',
	})
}

export const dataMonitorDataDetailList = listQuery => {
  return request({
    url: '/car/dataMonitor/dataMonitorDataDetail',
    method: 'post',
    data: {
      command_id: 63,
      carId: listQuery.carId,
	    currentTime:listQuery.currentTime,
      pageIndex: listQuery.pageIndex,
      pageSize: listQuery.pageSize,
    }
  })
}

export const download = (carNum,equipmentType) => {
  return request({
    url: '/car/download/'+carNum+'/'+equipmentType,
    method: 'post',
	responseType: 'blob'
  })
}

export const carTypeSave = carTypeForm => {
  return request({
    url: '/car/type/carTypeSave',
    method: 'post',
    data: {
      id: carTypeForm.addtypeId,
	  entityId:carTypeForm.addtypeEntityId,
	  name:carTypeForm.addtypeName,
      length: carTypeForm.addtypeLength,
      width: carTypeForm.addtypeWidth,
	  height: carTypeForm.addtypeHeight,
	  weight: carTypeForm.addtypeWeight,
	  carCost:carTypeForm.addtypeCarCost,
	  insurance:carTypeForm.addtypeInsurance,
	  maintenanceFees:carTypeForm.addtypeMaintenanceFees,
	  chargingFee:carTypeForm.addtypeChargingFee,
	  invoiceTax:carTypeForm.addtypeInvoiceTax,
	  deposit:carTypeForm.addtypeDeposit,
	  dailyFee:carTypeForm.addtypeDailyFee,
	  monthlyFee:carTypeForm.addtypeMonthlyFee,
	  annualFee:carTypeForm.addtypeAnnualFee
    }
  })
}

export const carTypeDelete = typeId => {
  return request({
    url: '/car/type/carTypeDelete/'+typeId,
    method: 'delete',
  })
}

export const getRecord = carId => {
  return request({
    url: '/car/voice/getRecord/'+carId,
    method: 'get',
  })
}

export const getCarTypes= entityId =>{
  return request({
    url: '/car/type/types',
    method: 'post',
    data:{
      entityId:entityId
    }
  })
}

export const taskList = carId => {
  return request({
    url: '/tasks/list/'+carId,
    method: 'get',
  })
}

export const getUnHandle = entityId =>{
  return request({
      url:'/warning/getUnHandle',
      method: 'post',
      data:{
          command_id: 0,
          entityId:entityId
      }
  })
}