<template>
  <div id="center">
    <div class="up">
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title">
        <!-- <p class="ml-3 colorBlue fw-b">{{item.title}}</p> -->
        <p class="ml-3 fw-b" :class="theme=='light'?'titleColor':'colorBlue'">{{item.title}}</p>
        <div>
          <dv-digital-flop :config="item.number" style="width:1.25rem;height:.625rem;" />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="ranking bg-color-black">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2 mb-1">日志报警</span>
        <!-- <dv-scroll-ranking-board :config="ranking" style="height:2.75rem" /> -->
        <dv-scroll-board :config="warnings" style="height:2.75rem" />
      </div>
      <div class="percent">
        <div class="item bg-color-black">
          <span>速度</span>
          <CenterChart :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />
        </div>
        <div class="item bg-color-black">
          <span>电量</span>
          <CenterChart :id="rate[1].id" :tips="rate[1].tips" :colorObj="rate[1].colorData" />
        </div>
        <div class="water bg-color-black">
          <dv-water-level-pond :config="water" style="height: 1.5rem" />
        </div>
      </div>
    </div>
    <dv-border-box-1 style="width:100%;height:1.1rem;">
      <div class="carId">
        <div class="bg-color-black carIdDiv">
          <p :class="theme=='light'?'carId':'carId-dark'">{{carId}} &nbsp;&nbsp;&nbsp;
            <img id="themeSvg" :src="theme?(theme=='light'?light:dark):light" style="height:70%;cursor:pointer" @click="changeTheme">
          </p>
        </div>
      </div>
    </dv-border-box-1>
  </div>
</template>

<script>
import CenterChart from "@/components/echart/center/centerChartRate";
import {getTotalTime,getOnlineTime,getUnHandle} from '@/api/car'

export default {
  props:{
    record:{
      type:Object,
    },
    carId:{
      type:String,
    },
    theme:{

    }
  },
  watch:{
    record:{
      handler:function(newVal){
        if (!newVal) {
          return
        }
        if (newVal.speed!='0') {
          this.rate[0].tips=parseInt(newVal.speed)
        }
        if(newVal.battery!='0'){
          this.rate[1].tips=parseInt(newVal.battery)
        }
        if(newVal.cleanBoxLeft!='0'||newVal.waterBoxLeft!='0'){
          this.water.data=[parseInt(newVal.cleanBoxLeft),parseInt(newVal.waterBoxLeft)]
        }
      },
      deep: true,
	    immediate: true,
    },
    carId:{
      handler:function(newVal){
        if (!newVal) {
          return
        }
        this.setOnlineTime()
      }
    },
    theme(){
      this.setWarning()
    }
  },
  data () {
    return {
      titleItem: [
        {
          title: "今年在线时长",
          number: {
            number: [120],
            toFixed: 1,
            content: "{nt}",
          }
        },
        {
          title: "本月在线时长",
          number: {
            number: [18],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "今日在线时长",
          number: {
            number: [2],
            toFixed: 1,
            content: "{nt}"
          }
        },
      ],
      ranking: {
        data: [],
        carousel: "single",
        unit: "小时"
      },
      water: {
        data: [24, 45],
        shape: "roundRect",
        formatter: "水压{value}%",
        waveNum: 3
      },
      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ],
      warnings:{
        data:[]
      },
      light:require('../assets/light.svg'),
      dark:require('../assets/dark.svg'),
    };
  },
  components: {
    CenterChart
    // centerChart1,
    // centerChart2
  },
  mounted(){
    this.setWarning()
    this.setOnlineTime()
  },
  methods:{
    changeTheme(){
      this.$emit("changeTheme")
    },
    setWarning(){
      getUnHandle(1).then(res=>{
        let data=res.data.map(item=>{
          return [item.carId,item.info]
        })
        if(this.theme=='light'){
          this.warnings={
            data:data,
            // oddRowBGC: "#0f1325", //奇数行
            // evenRowBGC: "#171c33", //偶数行
            oddRowBGC: "#0f132520", //奇数行
            evenRowBGC: "#171c3320", //偶数行
            rowNum: 5, //表格行数
            // align:['center','center']
          }
        }else{
          this.warnings={
            data:data,
            oddRowBGC: "#0f1325", //奇数行
            evenRowBGC: "#171c33", //偶数行
            // oddRowBGC: "#0f132520", //奇数行
            // evenRowBGC: "#171c3320", //偶数行
            rowNum: 5, //表格行数
            // align:['center','center']
          }
        }
        
      })
    },
    setOnlineTime(){
      // getTotalTime().then(res=>{
      //   let data=res.data.map(item=>{
      //     return{
      //       name:item.name,
      //       value:parseInt((item.totalDuration/3600).toFixed(0)),
      //     }
      //   })
      //   this.ranking={
      //     data: data,
      //     carousel: "single",
      //     unit: "小时"
      //   }
      // })
      getOnlineTime(this.carId).then(res=>{
        this.titleItem= [
          {
            title: "今年在线时长",
            number: {
              number: [res.data[0]/3600],
              toFixed: 0,
              content: "{nt}"
            }
          },
          {
            title: "本月在线时长",
            number: {
              number: [res.data[1]/3600],
              toFixed: 0,
              content: "{nt}"
            }
          },
          {
            title: "今日在线时长",
            number: {
              number: [res.data[2]/3600],
              toFixed: 0,
              content: "{nt}"
            }
          },
        ];
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 32%;
      height: 0.875rem;
    }
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 3.1875rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
    }
    .ranking {
      padding: 0.125rem;
      width: 59%;
    }
    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
      }
    }
  }
}
.carId{
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  // color: rgb(61, 231, 201);
  color: white;
  // color: rgba(0, 119, 255, 0.575);
  font-size: 0.4rem;
}
.carId-dark{
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  color: rgb(61, 231, 201);
  // color: white;
  // color: rgba(0, 119, 255, 0.575);
  font-size: 0.4rem;
}
.carIdDiv{
  width: 93%;
  height: 70%;
  justify-content:center;
  align-items:center;
  border-radius: 0.1rem;
}
.titleColor{
  color: yellow;
}
#themeSvg{
  margin-left: 10px;
}
</style>