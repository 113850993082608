<template>
	<div>
		<Chart :cdata="cdata" :theme="theme" />
	</div>
</template>

<script>
	import Chart from './chart.vue';
	import {
		carList
	} from '@/api/car'
	import {
		getLocation
	} from '@/api/geo'

	export default {
		props: {
			theme: {}
		},
		data() {
			return {
				provinces: [
					'甘肃', '青海', '四川', '河北', '云南', '贵州', '湖北', '河南', '山东', '江苏', '安徽',
					 '浙江', '江西', '福建', '广东', '湖南',
					'海南', '辽宁', '吉林', '黑龙江', '山西', '陕西', '台湾', '北京', '上海', '重庆', 
					'天津', '内蒙古', '广西', '西藏', '宁夏', '新疆',
					'香港', '澳门',
				],
				cdata: [{
					// 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
					name: '甘肃',
					value: 10,
					elseData: {
						// 这里放置地图 tooltip 里想显示的数据
					}
				}, ]
			}
		},
		components: {
			Chart,
		},
		mounted() {
			// this.randomData()
			this.setProvinceData()
		},
		methods: {
			randomNum(minNum, maxNum) {
				switch (arguments.length) {
					case 1:
						return parseInt(Math.random() * minNum + 1, 10);
						break;
					case 2:
						return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
						break;
					default:
						return 0;
						break;
				}
			},
			randomData() {
				this.cdata = this.provinces.map(item => {
					return {
						name: item,
						value: this.randomNum(1, 20)
					}
				})
			},
			setProvinceData() {
				carList({
					entityId: 1,
					pageIndex: 1,
					pageSize: 100,
					fuzzySearch: true
				}, {}).then(res => {
					// console.log(res.pageData.datas)
					let cars = res.pageData.datas
					let counts = new Array()
					for (let car of cars) {
						let lng = car.baiduLongitude
						let lat = car.baiduLatitude
						getLocation(lng, lat).then(res => {
							if (res.result) {
								let province = res.result.addressComponent.province
								province = province.substring(0, province.length - 1)
								if (province != '') {
									if (counts[province]) {
										counts[province]++
									} else {
										counts[province] = 1
									}
								}
							}
						})
					}
					setTimeout(() => {
						let data = []
						// for(let province in counts){
						//   data.push({
						//     name:province,
						//     value:counts[province]
						//   })
						// }
						data = this.provinces.map(item => {
							if (counts[item]) {
								return {
									name: item,
									value: counts[item]
								}
							} else {
								return {
									name: item,
									value: 0
								}
							}
						})

						// 后台真实数据
						// this.cdata=data;

						// 模拟数据
						this.cdata = [{
								name: "甘肃",
								value: 62
							},
							{
								name: "青海",
								value: 76
							},
							{
								name: "四川",
								value: 35
							},
							{
								name: "河北",
								value: 89
							},
							{
								name: "云南",
								value: 57
							},
							{
								name: "贵州",
								value: 65
							},
							{
								name: "河南",
								value: 78
							},
							{
								name: "山东",
								value: 45
							},{
								name: "江苏",
								value: 98
							},{
								name: "安徽",
								value: 72
							},{
								name: "浙江",
								value: 88
							},{
								name: "江西",
								value: 35
							},{
								name: "福建",
								value: 54
							},{
								name: "广东",
								value: 56
							},{
								name: "湖南",
								value: 45
							},{
								name: "辽宁",
								value: 31
							},{
								name: "吉林",
								value: 38
							},{
								name: "黑龙江",
								value: 15
							},{
								name: "山西",
								value: 39
							},{
								name: "陕西",
								value: 46
							},{
								name: "台湾",
								value: 0
							},{
								name: "北京",
								value: 125
							},{
								name: "上海",
								value: 160
							},{
								name: "重庆",
								value: 54
							},{
								name: "天津",
								value: 35
							},{
								name: "内蒙古",
								value: 8
							},{
								name: "广西",
								value: 68
							},{
								name: "西藏",
								value: 10
							},{
								name: "宁夏",
								value: 35
							},{
								name: "新疆",
								value: 7
							},{
								name: "香港",
								value: 32
							},{
								name: "澳门",
								value: 25
							}
						]
					}, 3000)
				})
			}
			// setCityData(){
			//   carList({entityId:1,pageIndex:1,pageSize:100,fuzzySearch:true},{}).then(res=>{
			//     // console.log(res.pageData.datas)
			//     let cars=res.pageData.datas
			//     let counts=new Array()
			//     for(let car of cars){
			//       let lng=car.baiduLongitude
			//       let lat=car.baiduLatitude
			//       getLocation(lng,lat).then(res=>{
			//         if (res.result) {
			//           let city=res.result.addressComponent.city
			//           if(city!=''){
			//             if(counts[city]){
			//               counts[city]++
			//             }else{
			//               counts[city]=1
			//             }
			//           }
			//         }
			//       })
			//     }
			//     setTimeout(()=>{
			//       let data=[]
			//       for(let city in counts){
			//         data.push({
			//           name:city,
			//           value:counts[city]
			//         })
			//       }
			//       this.cdata=data
			//     },3000)
			//   })
			// }
		}
	}
</script>

<style lang="scss" scoped>
</style>
