<template>
    <div>
        <Chart :cdata="cdata" />
    </div>
</template>

<script>
import Chart from "./chart.vue";
export default {
    data() {
        return {
            cdata: {},
        };
    },
    props:{
      res:{
        type:Object,
        default:null,
      }
    },
    watch:{
      res(newVal,oldVal){
        console.log(newVal,oldVal)
        this.setData(newVal)
      }
    },
    components: {
        Chart,
    },
    mounted() {
        this.setData(this.res);
    },
    beforeDestroy() {},
    methods: {
      /**
	   * 日期格式化
	   * @param {Object} fmt 
	   * @param {Object} date
	   */
	  dateFormat(fmt, date){
		  let ret;
		  date=new Date(date);
		  	    const opt = {
		  	        "Y+": date.getFullYear().toString(),        // 年
		  	        "m+": (date.getMonth() + 1).toString(),     // 月
		  	        "d+": date.getDate().toString(),            // 日
		  	        "H+": date.getHours().toString(),           // 时
		  	        "M+": date.getMinutes().toString(),         // 分
		  	        "S+": date.getSeconds().toString()          // 秒
		  	        // 有其他格式化字符需求可以继续添加，必须转化成字符串
		  	    };
		  	    for (let k in opt) {
		  	        ret = new RegExp("(" + k + ")").exec(fmt);
		  	        if (ret) {
		  	            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
		  	        };
		  	    };
		  	    return fmt;
	  },
        setData(res) {
          // console.log('bottom-right-chart:',res)
          if(!res)return;
          let resList=res.lst;
          let dataList = []; // 时间
          let valueList1 = []; //电流
          let valueList2 = []; //电压
          let valueList3 = []; //电量
          let valueList4_1 = []; //超声波1
          let valueList4_2 = []; //超声波2
          let valueList4_3 = []; //超声波3
          let valueList4_4 = []; //超声波4
          let valueList4_5 = []; //超声波5
          let valueList4_6 = []; //超声波6
          let valueList4_7 = []; //超声波7
          let valueList4_8 = []; //超声波8
          for(let i=0;i<resList.length;i++){
            dataList.push(this.dateFormat("YYYY-mm-dd HH:MM:SS", resList[i].createTime));
            valueList1.push(resList[i].powerLeft);
            valueList2.push(resList[i].voltage);
            valueList3.push(resList[i].electric);
            valueList4_1.push(resList[i].ultrasonic1);
            valueList4_2.push(resList[i].ultrasonic2);
            valueList4_3.push(resList[i].ultrasonic3);
            valueList4_4.push(resList[i].ultrasonic4);
            valueList4_5.push(resList[i].ultrasonic5);
            valueList4_6.push(resList[i].ultrasonic6);
            valueList4_7.push(resList[i].ultrasonic7);
            valueList4_8.push(resList[i].ultrasonic8);
			    }
          this.cdata = {
              title: {
                  text: "超声波(cm)",
				  textStyle: {
						fontWeight: "lighter",
						fontFamily: "Courier New"
				   },
              },
              legend: {
                  x: "150px",
                  y: "0px",
                  data: [
                      "前-左",
                      "前-右",
                      "后-左",
                      "后-右",
                      "左-上",
                      "左-下",
                      "右-上",
                      "右-下",
                  ],
              },
              grid:{
                x:"40",
                y:"40",
                width:"90%",
                height:"60%"// 高度
              },
              xAxis: {
                  data: dataList,
              },
              yAxis:{},
              series: [
                  {
                    name: "前-左",
                    type: "line",
                    data: valueList4_1,
                    markLine: {
                        silent: true,
                        data: [
                            {
                                yAxis: 100,
                            },
                            {
                                yAxis: 200,
                            },
                            {
                                yAxis: 300,
                            },
                            {
                                yAxis: 400,
                            },
                            {
                                yAxis: 500,
                            },
                        ],
                    },
                  },
                  {
                    name: "前-右",
                    type: "line",
                    data: valueList4_2,
                    markLine: {
                        silent: true,
                        data: [
                            {
                                yAxis: 100,
                            },
                            {
                                yAxis: 200,
                            },
                            {
                                yAxis: 300,
                            },
                            {
                                yAxis: 400,
                            },
                            {
                                yAxis: 500,
                            },
                        ],
                    },
                  },
                  {
                    name: "后-左",
                    type: "line",
                    data: valueList4_3,
                    markLine: {
                        silent: true,
                        data: [
                            {
                                yAxis: 100,
                            },
                            {
                                yAxis: 200,
                            },
                            {
                                yAxis: 300,
                            },
                            {
                                yAxis: 400,
                            },
                            {
                                yAxis: 500,
                            },
                        ],
                    },
                  },
                  {
                    name: "后-右",
                    type: "line",
                    data: valueList4_4,
                    markLine: {
                        silent: true,
                        data: [
                            {
                                yAxis: 100,
                            },
                            {
                                yAxis: 200,
                            },
                            {
                                yAxis: 300,
                            },
                            {
                                yAxis: 400,
                            },
                            {
                                yAxis: 500,
                            },
                        ],
                    },
                  },
                  {
                    name: "左-上",
                    type: "line",
                    data: valueList4_5,
                    markLine: {
                        silent: true,
                        data: [
                            {
                                yAxis: 100,
                            },
                            {
                                yAxis: 200,
                            },
                            {
                                yAxis: 300,
                            },
                            {
                                yAxis: 400,
                            },
                            {
                                yAxis: 500,
                            },
                        ],
                    },
                  },
                  {
                      name: "左-下",
                      type: "line",
                      data: valueList4_6,
                      markLine: {
                          silent: true,
                          data: [
                              {
                                  yAxis: 100,
                              },
                              {
                                  yAxis: 200,
                              },
                              {
                                  yAxis: 300,
                              },
                              {
                                  yAxis: 400,
                              },
                              {
                                  yAxis: 500,
                              },
                          ],
                      },
                  },
                  {
                      name: "右-上",
                      type: "line",
                      data: valueList4_7,
                      markLine: {
                          silent: true,
                          data: [
                              {
                                  yAxis: 100,
                              },
                              {
                                  yAxis: 200,
                              },
                              {
                                  yAxis: 300,
                              },
                              {
                                  yAxis: 400,
                              },
                              {
                                  yAxis: 500,
                              },
                          ],
                      },
                  },
                  {
                      name: "右-下",
                      type: "line",
                      data: valueList4_8,
                      markLine: {
                          silent: true,
                          data: [
                              {
                                  yAxis: 100,
                              },
                              {
                                  yAxis: 200,
                              },
                              {
                                  yAxis: 300,
                              },
                              {
                                  yAxis: 400,
                              },
                              {
                                  yAxis: 500,
                              },
                          ],
                      },
                  },
              ],
          };
        },
    },
};
</script>

<style lang="scss" scoped>
</style>