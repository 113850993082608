<template>
  <div>
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="3rem"
      width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = newData
      },
      immediate: true,
      deep: true
    },
  },
}
</script>