import axios from 'axios'

// axios.defaults.withCredentials=true;

// create an axios instance
const instance = axios.create({
  // baseURL: baseUrl,
  baseURL: process.env.VUE_APP_BASE_API,
  // timeout: 5000
	headers: {
	  'Content-Type': "application/json;charset=utf-8"
	},
    crossDomain: true
})

// request interceptor
instance.interceptors.request.use(
  config => {
    // do something before request is sent
    // 请求头添加token
    config.headers['x-user']='testAdmin'
    return config
  },
  error => {
    // do something with request error
    console.log('Error: '+error.message)  // for debug
    return Promise.reject(error)
  }
)

// response interceptor
instance.interceptors.response.use(
  response => {
    // 响应成功，更新token
    const res = response.data
    // console.log("response:",response)
    return res
  },
  error => {
    // 响应失败
    let status=error.response.status
    if(status==401){
        //token 失效
        
    }
    return Promise.reject(error)
  }
)

export default instance
