<template>
  <div id="centreRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2">IMU与超声波雷达</span>
      </div>
      <div class="d-flex jc-center body-box" style=" margin-top: 0;">
       <div id="imu" style="width: 100%;height:2rem"></div>
        <!-- <dv-capsule-chart :config="config" style="width: 100%;height:2rem" /> -->
        <!-- ---------------------------------------- -->
        <centreRight2Chart1 style="margin-top: -0.4rem;" :record="record" />
      </div>
    </div>
  </div>
</template>

<script>
import centreRight2Chart1 from "@/components/echart/centerRight/centerRightChart";
import * as THREE from 'three';
import {CSS2DRenderer, CSS2DObject} from 'three-css2drender'
import {randomNum} from '../utils/random'

export default {
  name:'centerRight2',
  props:{
    record:{
      type:Object,
    }
  },
  watch:{
    record:{
      handler:function(newVal){
        if (!newVal) {
          return
        }
        if(newVal.imut_x==0&&newVal.imut_y==0&&newVal.imut_z==0){
          newVal={...newVal,...this.randomIMU()}
        }

        setTimeout(()=>{
          const scale=10
        this.line1.geometry.dispose()
        // this.line2.geometry.dispose()

        this.line1.geometry=new THREE.BufferGeometry().setFromPoints( [
            new THREE.Vector3(0,0,0),
            new THREE.Vector3(newVal.imut_x/scale,newVal.imut_z/scale,newVal.imut_y/scale)] )
        },100)
        
      },
      deep: true,
	    immediate: true,
    },
  },
  data() {
    return {
      config: {
        data: [
          
        ]
      },
      camera: undefined,
      scene: undefined,
      renderer: undefined,

      line1:undefined,
      line2:undefined,

      labelRenderer:undefined,
    };
  },
  components: { centreRight2Chart1 },
  mounted() {
    this.initThree()
      setTimeout(()=>{
        this.animate()
      },100)
  },
  methods: {
    randomIMU(){
      return{
        imut_x:randomNum(50,150),
        imut_y:randomNum(50,150),
        imut_z:randomNum(50,150),
      }
    },
    initThree(){
      // console.log("initThree.")
      let container = document.querySelector("#imu")
      container.innerHTML=""
      this.scene = new THREE.Scene()
      this.camera = new THREE.PerspectiveCamera(75,container.clientWidth / container.clientHeight,0.1,1000)
      this.camera.position.set( 10, 10, 30 )
      this.scene.add(this.camera)

      this.scene.add( new THREE.AmbientLight( 0x222222 ) )
      const light = new THREE.PointLight( 0xffffff, 1 )
      this.camera.add( light )

      this.renderer = new THREE.WebGLRenderer( { antialias: true,alpha:true } )
      this.renderer.setClearColor('rgba(19, 25, 47, 0.1)',0.0); 
      this.renderer.setSize(container.clientWidth, container.clientHeight)
      container.appendChild(this.renderer.domElement)

      this.scene.add(new THREE.GridHelper(30,30))

      this.scene.add( new THREE.AxesHelper( 20 ) )

      // const xDiv = document.createElement( 'div' );
      // xDiv.className = 'label';
      // xDiv.textContent = 'x';
      // xDiv.style.fontSize='30px'
      // xDiv.style.color='#ffffff'
      // // xDiv.style.marginTop = '-1em';
      // const xLabel = new CSS2DObject( xDiv );
      // xLabel.position.set( 20, 0, 0 );
      // this.scene.add(xLabel)

      // const yDiv = document.createElement( 'div' );
      // yDiv.className = 'label';
      // yDiv.textContent = 'y';
      // yDiv.style.fontSize='30px'
      // yDiv.style.color='#ffffff'
      // // yDiv.style.marginTop = '-1em';
      // const yLabel = new CSS2DObject( yDiv );
      // yLabel.position.set( 0, 0, 20 );
      // this.scene.add(yLabel)

      // const zDiv = document.createElement( 'div' );
      // zDiv.className = 'label';
      // zDiv.textContent = 'z';
      // zDiv.style.fontSize='30px'
      // zDiv.style.color='#ffffff'
      // // zDiv.style.marginTop = '-1em';
      // const zLabel = new CSS2DObject( zDiv );
      // zLabel.position.set( 0, 17, -5 );
      // this.scene.add(zLabel)

      this.labelRenderer = new CSS2DRenderer();
      this.labelRenderer.setSize( container.clientWidth, container.clientHeight );
      this.labelRenderer.domElement.style.position = 'absolute';
      this.labelRenderer.domElement.style.top = '0px';
      container.appendChild( this.labelRenderer.domElement );

      var OrbitControls = require('three-orbit-controls')(THREE)
      const controls = new OrbitControls( this.camera, this.labelRenderer.domElement )
      controls.minDistance = 20
      controls.maxDistance = 50
      controls.maxPolarAngle = Math.PI / 2

      this.imu()
    },
    imu(){
      this.camera.lookAt( 0, 0, 0 )
      const material1 = new THREE.LineBasicMaterial( { color: 0xffffff,linewidth: 3, } )
      const points1 = []
      points1.push( new THREE.Vector3( 0, 0, 0 ) )
      points1.push( new THREE.Vector3( 10, 10, 10 ) )
      const geometry1 = new THREE.BufferGeometry().setFromPoints( points1 )
      this.line1 = new THREE.Line( geometry1, material1 )
      this.scene.add( this.line1 )

      // const material2 = new THREE.LineBasicMaterial( { color: 0x76D7C4,linewidth: 3, } )
      // const points2 = []
      // points2.push( new THREE.Vector3( 0, 0, 0 ) )
      // points2.push( new THREE.Vector3( -10, 10, -10 ) )
      // const geometry2 = new THREE.BufferGeometry().setFromPoints( points2 )
      // this.line2 = new THREE.Line( geometry2, material2 )
      // this.scene.add( this.line2 )

      // const geometry = new THREE.SphereBufferGeometry( 5, 32, 32 );
      // const material = new THREE.MeshBasicMaterial( {color: 0xffff00} );
      // const sphere = new THREE.Mesh( geometry, material );
      // this.scene.add( sphere );
    },
    animate(){
      requestAnimationFrame(this.animate)
      this.renderer.render(this.scene, this.camera)
      this.labelRenderer.render(this.scene,this.camera)
    },
  }
};
</script>

<style lang="scss">
#centreRight2 {
  padding: 0.0625rem;
  height: 5rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    padding: 0.0625rem;
    height: 5.0625rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
  }
}
</style>